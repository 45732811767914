import React from "react";
import Container from "react-bootstrap/Container"


const Contact = () => {

  return (
    <Container>
      <div className="pt-5 d-flex justify-content-center">
        <h2>Contact Us</h2>
      </div>
    </Container>
  )
}

export default Contact