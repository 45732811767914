
export const CrosswordData = [
  {
    image: require('../assets/img/Screenshot_17-9-2024_171236_.jpeg')
  },
  {
    image: require('../assets/img/servExample4.jpg')
  },
  {
    image: require('../assets/img/AmpersHatsResize.jpg')
  }
]

